<template>
  <div id="RebateCard">
    <div class="left_card card_item">
      <div class="card_title">
        <span class="title_text">{{ $t('home.totalCommission') }}</span>
        <el-popover
          placement="bottom"
          trigger="hover"
          width="220"
          :content="$t('home.HOVER')">
          <SvgIcon iconClass="help" slot="reference"></SvgIcon>
        </el-popover>
      </div>
      <div class="card_bottom">
        <div class="bottom_con">
          <span class="currency_symbol">{{ currAccountCurrency | currencySymbol }}</span>
          <span class="card_num">{{ totalBalance | currency('') }}</span>
          <span class="card_currency">{{ currAccountCurrency }}</span>
        </div>
        <div class="bottom_btn">
          <el-button type="primary" @click.native="applyRebate" data-testid="applyRebate">{{ $t('home.APPLY_FOR_A_REBATE') }}</el-button>
        </div>
      </div>
    </div>
    <div class="right_card card_item">
      <div class="card_title">
        <span class="title_text">{{ $t('home.availableBalance') }}</span>
      </div>
      <div class="card_bottom">
        <div class="bottom_con">
          <span class="currency_symbol">{{ currAccountCurrency | currencySymbol }}</span>
          <span class="card_num">{{ availableBalance | currency('') }}</span>
          <span class="card_currency">{{ currAccountCurrency }}</span>
        </div>
        <div class="bottom_btn">
          <el-button type="primary" @click="goToRebateWithdraw()" data-testid="WITHDRAW_REBATE">{{ $t('home.WITHDRAW_REBATE') }}</el-button>
          <el-button type="primary" @click="goToRebateTransfer()" data-testid="TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT">{{ $t('home.TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT') }}</el-button>
        </div>
      </div>
    </div>
    <!-- apply for rebate 弹窗 -->
    <SDialog :visible.sync="dialogVisible" :show-footer="false">
      <StatusMessage :title="$tc('home.applyRebateSuccessTip', 0)">
        <template #content>
          <span v-html="$tc('home.applyRebateSuccessTip', 1, { amount: appliedCommission, account: accountID })"></span>
          {{ $tc('home.applyRebateSuccessTip', 2) }}
        </template>
      </StatusMessage>
    </SDialog>
  </div>
</template>

<script>
import Hover from '@/components/Hover';
import { apiQueryAvailableBalance, apiApplyCommission, apiQueryBlacklist } from '@/resource';
import blackList from '@/mixins/blackList';

export default {
  name: 'rebate',
  components: { Hover },
  mixins: [blackList],
  props: { userID: '' },
  data() {
    return {
      dialogVisible: false,
      appliedCommission: 0,
      availableBalance: 0,
      lang: this.$store.state.common.lang
    };
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    currAccountCurrency() {
      return this.$store.state.behavior.CUR;
    },
    totalBalance: {
      get() {
        return this.$store.state.behavior.balance;
      },
      set(value) {
        return this.$store.commit('behavior/setBalance', value);
      }
    }
  },
  methods: {
    // 获取 可用余额
    queryAvailableBalance() {
      if (!this.accountID) return;
      apiQueryAvailableBalance({
        qUserId: this.userID,
        qAccount: this.accountID
      }).then(resp => {
        if (resp.data.code === 0) this.availableBalance = resp.data.data.availableBalance;
      }, this.$handleError);
    },
    // 申请佣金
    applyRebate() {
      if (!this.accountID) return;
      apiApplyCommission({
        qAccount: this.accountID,
        qUserId: this.userID
      }).then(resp => {
        if (resp.data.code === 0) {
          this.appliedCommission = this.$options.filters.currency(this.totalBalance, '');
          this.dialogVisible = true;
          this.$parent.$refs.accountNumber.rebatesList.map((item, idx) => {
            if (item.login === this.accountID) item.commission = 0;
          });
          this.totalBalance = 0;
          //save for passing new commission among different pages
          this.bus.$emit('updateRebateHistory', true);
        }
      }, this.$handleError);
    },
    // 内容初始化
    init() {
      this.queryAvailableBalance();
      this.$emit('accountIdAndCurrency', this.accountID, this.currAccountCurrency);
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/components/rebate.scss';
// @import '@/assets/css/components/vDialog.scss';
#RebateCard {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include screen-mobile {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  .card_item {
    width: 50%;
    padding: 24px;
    border-radius: 8px;
    color: $white;
    margin-bottom: 16px;
    @include screen-mobile {
      width: 100%;
    }
    .card_title {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
       .title_text {
        font-size: 16px;
        line-height: 24px;
        @include rtl-sass-prop(padding-right, padding-left, 8px);
      }
    }
    .card_bottom {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      @include screen-mobile {
        flex-direction: column;
      }
      .bottom_con {
        display: flex;
        align-items: center;
        @include screen-mobile {
          width: 100%;
        }
        .currency_symbol,
        .card_num {
          font-size: 32px;
          line-height: 48px;
          font-weight: 700;
        }
        .currency_symbol {
          @include rtl-sass-prop(padding-right, padding-left, 8px);
        }

        .card_currency {
          display: inline-block;
          padding: 4px 8px;
          border-radius: 10px;
          background-color: rgba($white, 0.6);
          font-size: 12px;
          font-weight: normal;
          color: $text-primary; 
          line-height: 12px;
          @include rtl-sass-prop(margin-left, margin-right, 8px);
        }
      }
    }

    &.left_card {
      @include rtl-sass-prop(margin-right, margin-left, 16px);
      background: linear-gradient(92.97deg, #04DACE 3.68%, #2482F7 98.91%), #FFFFFF;
      @include screen-mobile {
        @include rtl-sass-prop(margin-right, margin-left, 0px);
      }
    }
    &.right_card {
      background: linear-gradient(92.97deg, #2482F7 3.68%, #767BFF 98.91%), #FFFFFF;
    }
  }
}
</style>
