<template>
  <PageWrapper>
    <PagePanel>
      <PageSearchArea buttonTestId="UPDATE" @queryData="setStartAndEndDate">
        <template #form>
          <el-form label-position="top">
            <AccountNumber ref="accountNumber" @accountCallback="init"></AccountNumber>
            <DateRangePicker v-bind:start-date.sync="startTime" v-bind:end-date.sync="endTime"></DateRangePicker>
          </el-form>
        </template>
      </PageSearchArea>
    </PagePanel>
    <el-row :gutter="16" class="piece_list">
      <el-col :xg="24" :lg="6" :xl="4" v-for="item in pieceData" :key="item.index">
        <div
          :class="['piece_item', { active: currLineChartStatus === item.label }]"
          @click="onChangePiece(item)"
          :data-testid="item.label"
        >
          <div class="item_top">
            <div class="top_left">
              <img :src="require(`@/assets/images-1/home/${item.label}.png`)" alt="" />
              <span>{{ $t(`home.${item.label}`) }}</span>
            </div>
            <span
              class="el-icon-arrow-right"
              v-if="currLineChartStatus === item.label"
              @click.stop="onJumpToPage(item)"
              :data-testid="`goTo${item.go}Report`"
            ></span>
          </div>
          <div class="item_bottom">
            <span v-if="item.hasCurrency" class="bottom_currency">{{ currAccountCurrency | currencySymbol }}</span>
            <font class="reduced-font-size" v-if="item.isFormat">{{ accountDatas[item.value] | currency('') }}</font>
            <font class="reduced-font-size" v-else>{{ accountDatas[item.value] }}</font>
          </div>
        </div>
      </el-col>
    </el-row>
    <RebateCard ref="rebate" :userID="userID" @accountIdAndCurrency="getAccountIdAndCurrency"></RebateCard>
    <Chart ref="chart" :accountID="accountID" :startTime="startTime" :endTime="endTime" :userID="userID"></Chart>
    <section class="data_table">
      <PagePanel class="table_box">
        <div class="table_title">{{ $t('home.TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH') }}</div>
        <el-table :data="top6" height="300px" style="width: 100%;">
          <el-table-column prop="agentLowAccount" :label="$t('home.Account') + ' No'" width="120"></el-table-column>
          <el-table-column prop="name" :label="$t('home.Name')">
            <template slot-scope="scope">
              <span v-textOverflow v-html="scope.row.name" />
            </template>
          </el-table-column>
          <el-table-column prop="volume" :label="$t('home.Volume')" width="120">
            <template slot-scope="topPerformScope">{{ topPerformScope.row.volume | currency('') }}</template>
          </el-table-column>
          <el-table-column prop="commission" :label="$t('home.Rebate')" width="120">
            <template slot-scope="topPerformScope">
              {{ topPerformScope.row.commission | currency('') }}
            </template>
          </el-table-column>
        </el-table>
      </PagePanel>
      <PagePanel class="table_box">
        <div class="table_title">{{ $t('home.RECENTLY_OPENED_ACCOUNTS') }}</div>
        <el-table :data="nearestOpenAccount" height="300px" style="width: 100%;">
          <el-table-column prop="tradeAccount" :label="$t('home.Account') + ' No'" width="120"></el-table-column>
          <el-table-column prop="userName" :label="$t('home.Name')">
            <template slot-scope="scope">
              <span v-textOverflow v-html="scope.row.userName"></span>
            </template>
          </el-table-column>
          <el-table-column prop="balance" :label="$t('home.Balance')" width="120">
            <template slot-scope="scope">
              <!-- -0.00000009表示后台未查到数据，则用 - 显示 -->
              <span v-if="scope.row.balance == -0.00000009">-</span>
              <span v-else>{{ scope.row.balance | currency('') }}</span>
            </template>
          </el-table-column>
        </el-table>
      </PagePanel>
    </section>
  </PageWrapper>
</template>

<script>
import Chart from '@/views/home/components/chart';
import RebateCard from '@/views/home/components/RebateCard';
import AccountNumber from '@/components/customUI/AccountNumber';
import DateRangePicker from '@/components/customUI/DateRangePicker';

import {
  apiGetNearestOpenAccount,
  apiQueryRebateVolumeList,
  apiQueryTotalCommission,
  apiGetNewOpenUserTotal
} from '@/resource';
export default {
  data() {
    return {
      userID: this.$store.state.common.CUID,
      startTime: '',
      endTime: '',
      top6: [],
      nearestOpenAccount: [],
      accountID: '',
      currAccountCurrency: '',
      accountDatas: {
        // 总手数
        totalVolume: 0,
        // 总入金
        totalDeposit: 0,
        // 净入金
        netDeposit: 0,
        // 总出金
        totalWithdrawal: 0,
        // 总佣金
        totalCommission: 0,
        // 可用余额
        availableBalance: 0,
        // 新开账号数
        newOpenUserTotal: 0
      },
      // 当前显示的什么标签的趋势图
      currLineChartStatus: 'REBATE',
      pieceData: [
        { label: 'REBATE', value: 'totalCommission', isFormat: true, hasCurrency: true, go: 'Rebate' },
        { label: 'TOTAL_VOLUME', value: 'totalVolume', isFormat: true, hasCurrency: false, go: 'Rebate' },
        { label: 'NET_FUNDING', value: 'netDeposit', isFormat: true, hasCurrency: true, go: 'Ib' },
        { label: 'DEPOSITS', value: 'totalDeposit', isFormat: true, hasCurrency: true, go: 'Ib' },
        { label: 'WITHDRAW', value: 'totalWithdrawal', isFormat: true, hasCurrency: true, go: 'Ib' },
        { label: 'OPEND_ACCOUNT', value: 'newOpenUserTotal', isFormat: false, hasCurrency: false, go: 'Ib' }
      ]
    };
  },
  methods: {
    init() {
      this.$refs.rebate.init();
    },
    initData() {
      if (this.accountID) {
        Promise.all([
          this.getNearestOpenAccount(),
          this.queryRebateVolumeList(),
          this.queryTotalCommission(),
          this.getNewOpenUserTotal()
        ]);
      }
      // 每次请求数据，折线图恢复为默认字段
      this.currLineChartStatus = 'REBATE';
      // 更新折线图
      this.$nextTick(() => {
        this.$refs.chart.updateLineChart(this.currLineChartStatus);
        this.$refs.chart.updatePieChart();
      });
    },
    // 获取 最新6个开户账号
    getNearestOpenAccount() {
      apiGetNearestOpenAccount({
        mt4account: this.accountID
      }).then(resp => {
        if (resp.data.code === 0) this.nearestOpenAccount = resp.data.data;
      }, this.$handleError);
    },
    // 获取佣金top6
    queryRebateVolumeList() {
      apiQueryRebateVolumeList({
        qUserId: this.userID,
        qAccount: this.accountID
      }).then(resp => {
        if (resp.data.code === 0) this.top6 = resp.data.data;
      }, this.$handleError);
    },
    // 获取 佣金 交易量 净入金 总入金 总出金
    queryTotalCommission() {
      apiQueryTotalCommission({
        qUserId: this.userID,
        qAccount: this.accountID,
        qStartTime: this.startTime,
        qEndTime: this.endTime
      }).then(resp => {
        if (resp.data.code === 0) {
          const respData = resp.data.data;
          Object.keys(respData).map(key => {
            this.$set(this.accountDatas, key, respData[key]);
          });
        }
      }, this.$handleError);
    },
    // 获取 新开账号数
    getNewOpenUserTotal() {
      apiGetNewOpenUserTotal({
        mt4account: this.accountID,
        startTime: this.startTime,
        endTime: this.endTime
      }).then(resp => {
        if (resp.data.code === 0) this.$set(this.accountDatas, 'newOpenUserTotal', resp.data.data);
      }, this.$handleError);
    },
    setStartAndEndDate() {
      this.$nextTick(() => this.initData());
    },
    getAccountIdAndCurrency(accountID, currency) {
      this.accountID = accountID;
      this.currAccountCurrency = currency;
      if (this.startTime && this.endTime) this.initData();
    },
    onChangePiece(obj) {
      this.currLineChartStatus = obj.label;
    },
    onJumpToPage(obj) {
      if (obj.go === 'Rebate') {
        this.$router.push(`rebateReport`);
      }
      if (obj.go === 'Ib') {
        const cardMethod = this.currLineChartStatus == 'OPEND_ACCOUNT' ? `cardMethod=openedAccounts` : '';
        this.$router.push(`ibReport?${cardMethod}`);
      }
    }
  },
  watch: {
    currLineChartStatus(newStatus) {
      this.$nextTick(() => {
        return this.$refs.chart.updateLineChart(newStatus);
      });
    }
  },
  components: { Chart, RebateCard, AccountNumber, DateRangePicker },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/home/index.scss';
// @import '@/views/home/common.scss';
.piece_list {
  // width: 100%;
  margin-top: 16px;
  .piece_item {
    width: 100%;
    padding: 24px;
    background-color: $white;
    border-radius: 8px;
    margin-bottom: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    .item_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .top_left {
        display: flex;
        align-items: center;
        color: $text-secondary;
        font-size: 14px;

        img {
          width: 24px;
          @include rtl-sass-prop(margin-right, margin-left, 8px);
        }
      }
    }
    .item_bottom {
      color: $text-primary;
      font-weight: 700;
      font-size: 20px; 
      text-align: center;
      .bottom_currency {
        @include rtl-sass-prop(padding-right, padding-left, 5px);
      }
    }

    &:hover {
      box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.1);
    }
  }
  .active {
    border: 1px solid $primary;
  }
}
.data_table {
  width: 100%;
  display: flex;
  @include screen-mobile {
    flex-direction: column;
  }
  .table_title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: $text-primary;
    padding-bottom: 16px;
  }
  .table_box {
    width: 50%;
    margin-top: 0;
    margin-bottom: 16px;
    @include rtl-sass-prop(margin-right, margin-left, 16px);
    &:last-child {
      @include rtl-sass-prop(margin-right, margin-left, 0px);
    }
    @include screen-mobile {
      width: 100%;
      @include rtl-sass-prop(margin-right, margin-left, 0px);
    }
  }
}
</style>
